<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">门店提现审核</h3>
    </div>
    <div class="box">
      <div class="top">
        <span style="font-size: 14px; margin-left: 10px">门店名称:</span>
        <el-input v-model="from.name" placeholder="请输入门店名称"></el-input>
        <div class="time">
          <span class="orderTime">申请时间: </span>
          <el-date-picker
            v-model="TimeValue"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="left"
          >
          </el-date-picker>
          <span class="orderTime" style="margin-left: 20px">审核时间: </span>
          <el-date-picker
            v-model="checkTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="left"
          >
          </el-date-picker>
        </div>
        <br />
        <span style="font-size: 14px; margin-left: 10px">电话:</span>
        <el-input
          v-model="from.cellphone"
          placeholder="请输入门店电话"
        ></el-input>
        <span>门店ID:</span>
        <el-input
          placeholder="请输入门店ID"
          size="small"
          v-model="from.store_id"
          clearable
        >
        </el-input>
        <div style="display: inline; margin-right: 100px; padding-left: 10px">
          <span style="font-size: 14px; margin-right: 10px">门店状态:</span>
          <el-radio v-model="from.status" :label="1">待审核</el-radio>
          <el-radio v-model="from.status" :label="2">通过</el-radio>
          <el-radio v-model="from.status" :label="3">拒绝</el-radio>
          <el-radio v-model="from.status" :label="''">全部</el-radio>
        </div>

        <span
          class="addshop shou"
          style="
            margin-left: 10px;
            padding: 5px 15px;
            background-color: #0970cc;"
          @click="shaixuan"
          >筛选</span>
        <span
          class="addshop shou"
          style="
            margin-left: 10px;
            padding: 5px 15px;
            background-color: rgb(252, 85, 49);
            color: #FFFFFF;"
          @click="handleExport"
          >导出</span>
      </div>
      <span style="color: #000; margin-bottom:10px; display:block">提现列表:</span>
      <el-table
        :data="list"
        stripe
        border
        :header-row-style="{ height: '60px' }"
        :row-style="{ height: '30px' }"
        style="width: 90%"
      >
        <el-table-column prop="id" label="ID" width="80"> </el-table-column>
         <el-table-column prop="store_id" label="门店ID" width="80"> </el-table-column>
        <el-table-column prop="store.store_user.username" label="提现人">
        </el-table-column>
        <el-table-column prop="store.name" label="门店"> </el-table-column>
        <el-table-column prop="store.phone" label="电话">
        </el-table-column>
        <el-table-column prop="change_money" label="提现金额">
        </el-table-column>
        <el-table-column prop="service_charge" label="平台服务费">
        </el-table-column>
        <el-table-column prop="end_price" width="110" label="到账结算金额">
        </el-table-column>
        <el-table-column show-overflow-tooltip label="申请时间">
          <div slot-scope="scope">
            {{ scope.row.createtime | datefilter }}
          </div>
        </el-table-column>
        <el-table-column label="类型">
          <div>提现</div>
        </el-table-column>
        <el-table-column label="审核">
          <div class="shenhe" slot-scope="scope">
            <span v-show="scope.row.status == 2">已通过</span>
            <span v-show="scope.row.status == 3">已驳回</span>
            <el-button
              type="primary"
              round
              class="shou"
              size="mini"
              v-show="scope.row.status == 1"
              @click="shenhe(scope.row.id, scope.row.store.name)"
              >待审核</el-button
            >
          </div>
        </el-table-column>
        <el-table-column prop="shenhezhuangtai" label="审核进度">
          <div slot-scope="scope" class="jindu">
            <el-tag v-if="scope.row.status == 1" size="mini" type="info"
              >审核中</el-tag>
            <template v-else-if="scope.row.status == 2">
              <el-tag size="mini" effect="dark">已通过</el-tag>
              <span
                v-if="scope.row.pay_status == 1 || scope.row.pay_status == 3"
                class="shou dauan"
                @click="remit(scope.row.id)"
                >待打款</span>
              <el-tag
                v-if="scope.row.pay_status == 2"
                size="mini"
                type="success"
                effect="dark"
                >已打款</el-tag>
              <el-tag
                v-if="scope.row.pay_status == 3"
                size="mini"
                effect="dark"
                type="danger"
                >打款失败</el-tag>
            </template>
            <el-tag v-else size="mini" type="danger" effect="dark"
              >已驳回</el-tag>
          </div>
        </el-table-column>
        <el-table-column width="180" label="审核通过时间">
          <div slot-scope="scope">
            <span v-if="scope.row.pay_time">{{scope.row.pay_time | datefilter}}</span>
            <span v-else>暂无</span>
          </div>
        </el-table-column>
        <el-table-column width="180" label="提现明细">
          <template slot-scope="scope">
            <el-button
              type="primary"
              round
              class="shou"
              size="mini"
              @click="lookDetail(scope.row.id)"
              >查看明细</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="crtChg"
        :current-page="from.page"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
	<!-- 导出弹窗 -->
    <el-dialog title="导出范围" :visible.sync="exportShow" width="25%">
      <div>
        <p style="padding: 0 0 20px 0; font-size: 12px; color: #e6a23c">
          将导出满足筛选条件的门店!
        </p>
        <el-radio v-model="exportType" :label="1">当前页</el-radio>
        <el-radio v-model="exportType" :label="2">导出所有</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportShows">取 消</el-button>
        <el-button type="primary" @click="exportShop2">确 定</el-button>
      </span>
    </el-dialog>
  <!-- 提现弹窗 -->
    <el-dialog title="提现申请" :visible.sync="dialogShenHeShow" width="18%">
      <p>
        是否同意<span style="color: #111">{{ username }}</span
        >的提现申请?
      </p>
      <div class="txbtn">
        <el-button type="success" @click="TXsuccess">通过</el-button>
        <el-button type="danger" @click="TXerror">驳回</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogShenHeShow = false"
          >取 消</el-button>
      </span>
    </el-dialog>
    <!-- 提现明细弹窗 -->
    <el-dialog title="提现明细列表" :visible.sync="withdrawDetailShow" width="80%">
      <div style="position: absolute; right: 90px; top: 16px; display: flex; height: 80px; align-items: center;">
        <div class="addshop shou"
              style="
               background-color: rgb(252, 85, 49);
                padding: 5px 10px;
                color: rgb(255, 255, 255);
                border-radius: 4px;
                width: 105px;
                margin-right: 20px
              "
              @click="detailexport"
              >导出订单 <i class="el-icon-folder-opened"></i> </div>
              
             <el-radio v-model="detailFrom.export_type" :label="1">当前页</el-radio>
            <el-radio v-model="detailFrom.export_type" :label="2">导出所有</el-radio>
      </div>
      
      <el-table
        :data="withdrawDetailList"
        stripe
        border
        :header-row-style="{ height: '60px' }"
        :row-style="{ height: '30px' }"
      >
        <!-- <el-table-column prop="id" label="ID" width="80"> </el-table-column> -->
        <el-table-column prop="order_no" label="订单编号">
        </el-table-column>
        <el-table-column prop="store_id" label="门店ID" width="80"> </el-table-column>
        <el-table-column prop="name" label="收货人名称"> </el-table-column>
        <el-table-column prop="mobile" label="收货人电话">
        </el-table-column>
        <el-table-column prop="address" label="收货人地址">
        </el-table-column>
        <el-table-column prop="type_name" label="订单分类">
        </el-table-column>
        <el-table-column prop="total_price" label="订单总价">
        </el-table-column>
        <el-table-column label="订单完成时间">
           <template slot-scope="scope">
            {{ scope.row.confirm_time | datefilter }}
          </template>
        </el-table-column>
        <el-table-column prop="pay_price" label="支付金额">
        </el-table-column>
        <el-table-column prop="end_price" label="门店收益">
        </el-table-column>
        <el-table-column prop="service_price" label="服务费">
        </el-table-column>
         <el-table-column prop="is_offline" label="订单类型">
        </el-table-column>
        <!-- <el-table-column label="订单类型">
          <template slot-scope="scope">
            {{ scope.row.is_offline == 1 ? "自提订单" : "物流订单" }}
          </template>
        </el-table-column> -->
        <el-table-column prop="express_no" label="快递单号">
        </el-table-column>
        <el-table-column prop="express" label="快递公司">
        </el-table-column>
        <el-table-column prop="store_name" label="所属门店">
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="withdrawDetailCrtChg"
        :current-page="detailFrom.page"
        layout="total, prev, pager, next, jumper"
        :total="withdrawDetailTotal"
      >
      </el-pagination>
    </el-dialog>
  </el-card>
</template>

<script>
import { shopCapital, shopCheck, shopRemit, txOrderDetail } from "@/api/api.js";
import { filenameFormat } from "@/utils/index";
export default {
  components: {},
  name: "financeList",
  data() {
    return {
      from: {
        status: "", // 状态
        name: "", // 店主
        cellphone: "", // 电话
        start_time: "", //  开始时间
        end_time: "", //  结束时间
        page: 1, // 页码
        check_start_time: "",
        check_end_time: "",
        export: "",
        export_type: "",
        store_id: ''
      },
      username: "",
      shopTiXianId: null,
      TimeValue: null,
      checkTime: null,
      dialogShenHeShow: false,
      list: [],
      total: 10,
      exportShow: false,
      exportType: 1,
      withdrawDetailShow: false, //提现明细弹窗控制
      withdrawDetailList: [], //提现明细列表
      withdrawDetailpage: 1, //页码
      withdrawDetailTotal: 0, //总条数
      detailFrom:{
        id: '',
        export: '',
        export_type: '',
        page: 1,
      }
    };
  },
  created() {
    this.getlist();
  },
  watch: {
    checkTime: function (newVal, oldVal) {
      if (newVal != null) {
        this.from.check_start_time = this.$timeTo.time4(this.checkTime[0]);
        this.from.check_end_time = this.$timeTo.time4(this.checkTime[1]);
      } else {
        this.from.check_start_time = "";
        this.from.check_end_time = "";
      }
    },
    TimeValue: function (newVal, oldVal) {
      if (newVal != null) {
        this.from.start_time = this.$timeTo.time4(this.TimeValue[0]);
        this.from.end_time = this.$timeTo.time4(this.TimeValue[1]);
      } else {
        this.from.start_time = "";
        this.from.end_time = "";
      }
    }
  },
  methods: {
    crtChg(e) {
      this.from.page = e;
      this, this.getlist();
    },
    exportShows() {
      this.exportShow = false;
    },
    exportShop2() {
      this.from.export = "export";
      this.from.export_type = this.exportType;
      this.getlist();
      this.from.export = "";
    },
    // 详情列表导出
    async detailexport(){
      if(!this.detailFrom.export_type){
       return this.$message.error('请选择导出范围');
      }
      this.detailFrom.export = 'export'
      let { data, types, request } = await txOrderDetail(this.detailFrom, "blob");
        let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
        // let filename = decodeURI(
        //   request.getResponseHeader("content-disposition").split("=")[1]
        // );
        let objectUrl = URL.createObjectURL(blob); //生成下载链接
        let a = document.createElement("a"); //创建a标签用于文件下载
        a.href = objectUrl; //赋值下载路径
        a.download = filenameFormat(
          request.getResponseHeader("content-disposition")
        ); //下载的文件名称（非必填）
        document.body.appendChild(a); //插入DOM树
        a.click(); //点击a标签触发
        document.body.removeChild(a); //删除a标签
    },
    handleExport () {
      this.exportShow = true;
    },
    async getlist() {
      if (this.exportShow) {
        let { data, types, request } = await shopCapital(this.from, "blob");
        let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
        // let filename = decodeURI(
        //   request.getResponseHeader("content-disposition").split("=")[1]
        // );
        this.exportShow = false;
        let objectUrl = URL.createObjectURL(blob); //生成下载链接
        let a = document.createElement("a"); //创建a标签用于文件下载
        a.href = objectUrl; //赋值下载路径
        a.download = filenameFormat(
          request.getResponseHeader("content-disposition")
        ); //下载的文件名称（非必填）
        document.body.appendChild(a); //插入DOM树
        a.click(); //点击a标签触发
        document.body.removeChild(a); //删除a标签
      } else {
        const { data } = await shopCapital(this.from);
        if (data.code != 200) return this.$message.error(data.data);
        this.list = data.data.store_capital_list.data;
        this.total = data.data.store_capital_list.total;
        console.log(this.list);
//         this.list.forEach((item) => {
//           item.pay_time = this.$timeTo.time3(item.pay_time);
//         });
      }
    },
    shaixuan() {
      this.from.page = 1;
      this.getlist();
    },
    shenhe(id, name) {
      console.log(name);
      this.username = name;
      this.shopTiXianId = id;
      this.dialogShenHeShow = true;
    },
    async TXsuccess() {
      const { data } = await shopCheck({ id: this.shopTiXianId, status: 2 });
      this.dialogShenHeShow = false;
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
      this.getlist();
    },
    async TXerror() {
      const { data } = await shopCheck({ id: this.shopTiXianId, status: 3 });
      this.dialogShenHeShow = false;
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success(data.data);
      this.getlist();
    },
    remit(id) {
      this.$confirm("您是否要真的要打款吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(async () => {
          const { data } = await shopRemit({ id });
          if (data.code != 200) return this.$message.error(data.data);
          this.getlist();
          this.$message({
            type: "success",
            message: "付款成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  // 门店提现明细,列表数据
    async lookDetail(id){
      this.detailFrom.export_type = ''
      this.withdrawDetailShow = true
      this.detailFrom.id = id
       const { data } = await txOrderDetail(this.detailFrom);
      if (data.code != 200) return this.$message.error(data.data);
      this.withdrawDetailList = data.data.data
      this.withdrawDetailTotal = data.data.total
      console.log('data======',data)
    },
    // 翻页
    withdrawDetailCrtChg(e){
      this.detailFrom.page = e
      this.lookDetail(this.detailFrom.id)
    },
  },
  created() {
    this.getlist();
  },
};
</script>

<style scoped lang='less'>
.box {
  .top {
    background-color: #f1f3f4;
    margin: 0 0 20px 0;
    border-radius: 5px;
    box-shadow: 4px 4px 3px #e9ebec;
    .el-input {
      width: 300px;
      margin: 10px;
      .el-input__inner {
        height: 32px;
      }
    }
    .addshop {
      padding: 5px 10px;
      color: #f1f1f1;
      border-radius: 3px;
      transition: font-size 0.2s;
    }
    .time {
      padding: 10px;
      display: inline-block;
    }
  }
  .shenhe {
    span {
      color: #555;
      font-size: 14px;
      &:last-child {
        border-bottom: 1px solid #555;
        &:hover {
          color: #111;
          border-bottom: 1px solid #111;
        }
      }
    }
  }
}
.dauan {
  color: #555;
  font-size: 14px;
  margin: 12px;
  &:hover {
    color: #111;
    border-bottom: 1px solid #111;
  }
}
.txbtn {
  margin-top: 50px;
  display: flex;
}
.el-dialog {
  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
</style>